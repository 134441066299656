"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.stopPersist = void 0;
const StorageConfiguration_1 = require("./StorageConfiguration");
const isPersistence_1 = require("./isPersistence");
function stopPersist(target) {
    if (isPersistence_1.isPersistence(target)) {
        const disposers = StorageConfiguration_1.StorageConfiguration.getDisposers(target);
        disposers.forEach((disposers) => disposers());
        StorageConfiguration_1.StorageConfiguration.clearDisposers(target);
    }
}
exports.stopPersist = stopPersist;
