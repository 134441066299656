"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getObservableTargetObject = exports.getObjectKeys = exports.JSONParse = exports.mobxNewestVersionSelect = void 0;
const mobx_1 = require("mobx");
function mobxNewestVersionSelect(v6, other) {
    const dependenciesMobxVersion = require('mobx/package.json').version;
    const [major] = dependenciesMobxVersion.split('.');
    return Number(major) < 6 ? other : v6;
}
exports.mobxNewestVersionSelect = mobxNewestVersionSelect;
function JSONParse(json) {
    let ret = undefined;
    try {
        ret = JSON.parse(json);
    }
    catch (error) {
        console.warn('JSON Parse error', { json, error });
    }
    finally {
        return ret;
    }
}
exports.JSONParse = JSONParse;
function getObjectKeys(target) {
    return Object.keys(target);
}
exports.getObjectKeys = getObjectKeys;
function getObservableTargetObject(target, properties) {
    return properties.reduce((result, property) => {
        const observableProperty = mobx_1.isObservableProp(target, String(property));
        const computedProperty = mobx_1.isComputedProp(target, String(property));
        if (!observableProperty || computedProperty) {
            if (computedProperty) {
                console.warn('The property `' + property + '` is computed and will not persist.');
                return result;
            }
            console.warn('The property `' + property + '` is not observable and not affected reaction, but will persist.');
        }
        if (target.hasOwnProperty(property)) {
            let value = target[property];
            if (Array.isArray(value)) {
                value = value.slice();
            }
            return Object.assign(Object.assign({}, result), { [property]: target[property] });
        }
        return result;
    }, {});
}
exports.getObservableTargetObject = getObservableTargetObject;
