"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.startPersist = void 0;
const StorageConfiguration_1 = require("./StorageConfiguration");
const isPersistence_1 = require("./isPersistence");
function startPersist(target) {
    if (isPersistence_1.isPersistence(target)) {
        const disposers = StorageConfiguration_1.StorageConfiguration.getDisposers(target);
        if (!disposers.length) {
            const startPersistFunction = StorageConfiguration_1.StorageConfiguration.getStartPersist(target);
            startPersistFunction === null || startPersistFunction === void 0 ? void 0 : startPersistFunction()(target);
        }
    }
}
exports.startPersist = startPersist;
