"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isSynchronized = void 0;
const StorageConfiguration_1 = require("./StorageConfiguration");
const isPersistence_1 = require("./isPersistence");
function isSynchronized(target) {
    if (isPersistence_1.isPersistence(target)) {
        return StorageConfiguration_1.StorageConfiguration.getIsSynchronized(target);
    }
    console.warn('Only stores that persistence can use `isSynchronized` function.');
    return false;
}
exports.isSynchronized = isSynchronized;
