"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.rehydrate = void 0;
const mobx_1 = require("mobx");
const isSynchronized_1 = require("./isSynchronized");
function rehydrate(target) {
    return new Promise((resolve) => {
        const disposer = mobx_1.autorun(() => {
            if (isSynchronized_1.isSynchronized(target)) {
                disposer === null || disposer === void 0 ? void 0 : disposer();
                resolve();
            }
        });
    });
}
exports.rehydrate = rehydrate;
