"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StorageConfiguration = void 0;
const mobx_1 = require("mobx");
class StorageConfigurationStatic {
    constructor() {
        this.adapterMap = new Map();
        this.disposersMap = new Map();
        this.isSynchronizedMap = mobx_1.observable.map();
        this.startPersistMap = new Map();
        this.setAdapter = (storageName, adapter) => {
            this.adapterMap.set(storageName, adapter);
        };
        this.setDisposers = (target, disposers) => {
            this.disposersMap.set(target._storageName, disposers);
        };
        this.setStartPersist = (storageName, startPersist) => {
            this.startPersistMap.set(storageName, startPersist);
        };
        this.setIsSynchronized = (target, isSynchronized) => {
            this.isSynchronizedMap.set(target._storageName, isSynchronized);
        };
        this.getAdapter = (target) => {
            return this.adapterMap.get(target._storageName);
        };
        this.getDisposers = (target) => {
            return this.disposersMap.get(target._storageName) || [];
        };
        this.getIsSynchronized = (target) => {
            return this.isSynchronizedMap.get(target._storageName) || false;
        };
        this.getStartPersist = (target) => {
            return this.startPersistMap.get(target._storageName);
        };
        this.clearDisposers = (target) => {
            return this.disposersMap.delete(target._storageName);
        };
    }
}
__decorate([
    mobx_1.action
], StorageConfigurationStatic.prototype, "setIsSynchronized", void 0);
exports.StorageConfiguration = new StorageConfigurationStatic();
