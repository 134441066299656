var _Symbol = require("@babel/runtime-corejs3/core-js/symbol");

var _isIterable = require("@babel/runtime-corejs3/core-js/is-iterable");

var _Array$from = require("@babel/runtime-corejs3/core-js/array/from");

function _iterableToArray(iter) {
  if (typeof _Symbol !== "undefined" && _isIterable(Object(iter))) return _Array$from(iter);
}

module.exports = _iterableToArray;
module.exports["default"] = module.exports, module.exports.__esModule = true;