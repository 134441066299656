"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.clearPersist = void 0;
const StorageConfiguration_1 = require("./StorageConfiguration");
const isPersistence_1 = require("./isPersistence");
function clearPersist(target) {
    if (isPersistence_1.isPersistence(target)) {
        const storageAdapter = StorageConfiguration_1.StorageConfiguration.getAdapter(target);
        if (storageAdapter) {
            return storageAdapter.writeInStorage(target._storageName || target.constructor.name, {});
        }
    }
    return Promise.resolve();
}
exports.clearPersist = clearPersist;
