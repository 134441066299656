"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.persistenceDecorator = void 0;
const mobx_1 = require("mobx");
const StorageConfiguration_1 = require("./StorageConfiguration");
const utils_1 = require("./utils");
function getTargetPrototype(target) {
    return utils_1.mobxNewestVersionSelect(() => target, 
    // @ts-ignore
    () => target.prototype)();
}
function persistenceDecorator(options) {
    const persistenceCreator = (options, skipSynchronization) => (target) => {
        StorageConfiguration_1.StorageConfiguration.setAdapter(options.name, options.adapter);
        const targetPrototype = getTargetPrototype(target);
        const properties = options.properties;
        const extendObservableWrapper = utils_1.mobxNewestVersionSelect(Object.assign, mobx_1.extendObservable);
        const observableTargetPrototype = extendObservableWrapper(targetPrototype, {
            _isPersistence: true,
            _storageName: options.name,
            get _asJS() {
                return utils_1.getObservableTargetObject(targetPrototype, properties);
            },
        });
        const disposer = mobx_1.reaction(utils_1.mobxNewestVersionSelect(() => utils_1.getObservableTargetObject(targetPrototype, properties), 
        // @ts-ignore
        () => targetPrototype._asJS), (jsObject) => options.adapter.writeInStorage(options.name, jsObject), options.reactionOptions);
        StorageConfiguration_1.StorageConfiguration.setDisposers(targetPrototype, [disposer]);
        if (!skipSynchronization) {
            options.adapter.readFromStorage(options.name).then(mobx_1.action((content) => {
                if (content) {
                    utils_1.getObjectKeys(content).forEach((property) => {
                        if (targetPrototype[property] instanceof mobx_1.ObservableMap) {
                            const targetPartial = targetPrototype[property];
                            const mapSource = utils_1.getObjectKeys(content[property]).reduce((p, k) => {
                                p.push([k, content[property][k]]);
                                return p;
                            }, []);
                            const observableMap = new Map(mapSource);
                            targetPrototype[property] = observableMap;
                        }
                        else {
                            targetPrototype[property] = content[property];
                        }
                    });
                }
                StorageConfiguration_1.StorageConfiguration.setIsSynchronized(targetPrototype, true);
            }));
        }
        return utils_1.mobxNewestVersionSelect(() => observableTargetPrototype, () => target)();
    };
    StorageConfiguration_1.StorageConfiguration.setStartPersist(options.name, () => persistenceCreator(options, true));
    return persistenceCreator(options);
}
exports.persistenceDecorator = persistenceDecorator;
