"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StorageAdapter = void 0;
const utils_1 = require("./utils");
class StorageAdapter {
    constructor(options) {
        this.write = options.write;
        this.read = options.read;
    }
    writeInStorage(name, content) {
        return new Promise((resolve, reject) => {
            const contentString = JSON.stringify(content);
            this.write(name, contentString)
                .then(resolve)
                .catch((error) => {
                console.warn(`StorageAdapter.writeInStorage [${name}]`, error);
                reject(error);
            });
        });
    }
    readFromStorage(name) {
        return new Promise((resolve, reject) => {
            this.read(name)
                .then((content) => {
                if (!content)
                    return resolve(undefined);
                const contentObject = utils_1.JSONParse(content);
                return resolve(contentObject);
            })
                .catch((error) => {
                console.warn(`StorageAdapter.readFromStorage [${name}]`, error);
                reject(error);
            });
        });
    }
}
exports.StorageAdapter = StorageAdapter;
